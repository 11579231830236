// src/components/MainContent.js
import React, { useState, useEffect } from 'react';

// Get da API
import getDashboardData from '../../api/dashboardData';
import { startDataUpdate } from '../../api/dashboardData';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaucet, faTint, faMoneyBillWave, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';

// Bibliotecas para Gerar Relatório 
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Componentes
import Card from '../Card/Card';
import Dropdown from '../Dropdown/Dropdown'
import ProgressCard from '../ProgressCard/ProgressCard';
import PizzaChart from '../PizzaChart/PizzaChart';
import Chart from '../Chart/Chart';
import BotaoRelatorio from '../BotaoRelatorio/BotaoRelatorio';
import WaterConsumptionCard from '../WaterConsumptionCard/WaterConsumptionCard';

// Estilo da Página
import './MainContent.css'; 


const MainContent = () => {

    const [selectedOption, setSelectedOption] = useState(''); // Dropdown 1
    const [selectedOptionDate, setSelectedOptionDate] = useState(''); // Dropdown 2
    const [selectedLabel, setSelectedLabel] = useState('Jan 2024')

    const [labels, setLabels] = useState([]);
    const [dailyData, setDailyData] = useState([]);
    const [data, setData] = useState([]);
    const [labelsPizza, setLabelsPizza] = useState([]);

    const [dashboardData, setDashboardData] = useState(null); // Dados

    const [waterLevelPercentage, setWaterLevelPercentage] = useState(1);
    const [waterConsumptionLiters, setWaterConsumptionLiters] = useState(0);



    // DADOS DA API --------------------------------------------------------------------
    useEffect(() => {
        console.log("Iniciando atualização de dados...");
        // Atualização periódica (60 segundos)
        const clearDataUpdate = startDataUpdate(setDashboardData, 60000); 
        
        // Limpa o intervalo quando o componente é desmontado
        return () => clearDataUpdate(); 
    }, []);

    // if (!dashboardData) {
    //     return <div>Carregando dados...</div>;
    // }
    // ---------------------------------------------------------------------------------


    // Dados dos Gráficos --------------------------------------------------------------
    useEffect(() => {
        const leituras = dashboardData?.leituras_atuais || [];
        console.log(dashboardData)
        if (leituras.length > 0) {
            console.log("TO AQUI")
            // Dados Gráfico de barra ----------------------------------------------------------
            const newLabels = leituras.map(leitura => {
                const date = new Date(leitura.date);
                const day = date.getDate();
                const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
                return `${day} ${month}`;
            });
            const newDailyData = leituras.map(leitura => leitura.count);

            // Dados Gráfico de pizza (trocar pela lógica) -------------------------------------
            const sumByDayOfWeek = leituras.reduce((acc, leitura) => {
                const day = new Date(leitura.date).getDay();
                if (!acc[day]) acc[day] = { sum: 0, count: 0 };
                acc[day].sum += leitura.count;
                acc[day].count += 1;
                return acc;
            }, {});

            const newData = Object.values(sumByDayOfWeek).map(day => day.sum / day.count);

            setLabels(newLabels);
            setDailyData(newDailyData);
            setData(newData);
            setLabelsPizza(['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom']);
        } else {
            // console.log("TO NO OUTRO AQUI")
            setLabels([
                '01 Nov', '02 Nov', '03 Nov', '04 Nov', '05 Nov', '06 Nov', '07 Nov',
                '08 Nov', '09 Nov', '10 Nov', '11 Nov', '12 Nov', '13 Nov', '14 Nov',
                '15 Nov', '16 Nov', '17 Nov', '18 Nov', '19 Nov', '20 Nov', '21 Nov',
                '22 Nov', '23 Nov', '24 Nov', '25 Nov', '26 Nov', '27 Nov', '28 Nov',
                '29 Nov', '30 Nov'
            ]); 
            setDailyData([
                20, 22, 25, 18, 15, 17, 26,
                28, 24, 22, 19, 30, 25, 27,
                21, 24, 23, 26, 18, 20, 22,
                25, 27, 24, 23, 26, 28, 19,
                21, 23
            ]);
            // console.log(labels)
            
            setData([12, 14, 13, 17, 14, 16, 13]); 
            setLabelsPizza(['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom']);
        }

    }, [dashboardData]);
    // ---------------------------------------------------------------------------------


    // Consumo em Tempo Real -----------------------------------------------------------
    useEffect(() => {
        if (dashboardData) {
            const consumoRemoto =dashboardData.leituras_atuais.reduce((acc, leitura) => acc + leitura.count, 0)
            const percentage = Math.round((consumoRemoto / dashboardData.consumo_projetado) * 100)
            console.log(percentage);
            setWaterLevelPercentage(percentage);
            console.log(waterLevelPercentage);
            setWaterConsumptionLiters(consumoRemoto);
        }
    }, [dashboardData]);
    // ---------------------------------------------------------------------------------
    

    // Dropdowns -----------------------------------------------------------------------
    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleDropdownDateChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOptionDate(selectedValue);
        const selectedOption = dropdownOptionsDate.find(option => option.value === selectedValue);
        setSelectedLabel(selectedOption ? selectedOption.label : 'Consumo');
        console.log(selectedLabel);
    };

    const dropdownOptions = [
        { label: 'Diário', value: '1' },
        { label: 'Mensal', value: '2' },
    ];
    const dropdownOptionsDate = [
        // desenvolver a lógica para pegar os meses que o cliente possui
        { label: 'Jan 2024', value: '1' }, // Dummy
        { label: 'Dez 2023', value: '2' }, // Dummy
    ];
    // ---------------------------------------------------------------------------------


    // Botão Gerar Relatório -----------------------------------------------------------
    const handleRelatorioClick = () => {
        console.log('Gerando relatório...');
        const input = document.getElementById('mainContent');
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: [canvas.width, canvas.height]
            });
            pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
            pdf.save(`Relatório_${selectedLabel}.pdf`);
        });
    };
    // ---------------------------------------------------------------------------------


    // Barra de Progresso --------------------------------------------------------------

    // ---------------------------------------------------------------------------------


    // Dados dos Cards -----------------------------------------------------------------
    const cardsData = dashboardData ? [
        {
            title: 'CONSUMO DE ÁGUA DO MÊS ATUAL',
            value: `${dashboardData.consumo_atual} Litros`,
            icon: <FontAwesomeIcon icon={faFaucet} />,
            className: 'blue-border'
        },
        {
            title: 'CONSUMO DE ÁGUA PROJETADO',
            value: `${dashboardData.consumo_projetado.toFixed(2)} Litros`,
            icon: <FontAwesomeIcon icon={faTint} />,
            className: 'blue-border'
        },
        {
            title: 'VALOR PROJETADO DA CONTA',
            value: `R$ ${dashboardData.valor_projetado.toFixed(2)}`,
            icon: <FontAwesomeIcon icon={faMoneyBillWave} />,
            className: 'green-border'
        },
        {
            title: 'ESTIMATIVA DE ECONOMIA',
            value: `R$ ${dashboardData.estimativa_economia.toFixed(2)}`,
            icon: <FontAwesomeIcon icon={faHandHoldingUsd} />,
            className: 'green-border'
        }
    ] : [];
    // ---------------------------------------------------------------------------------    
    // ---------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------
    // Renderização da página ----------------------------------------------------------
    return (
        <div id="mainContent" className="main-content">
            <div className='dashboard-header'>
                <div className='dashboard-header-text'>Dashboard - SQS 211 Bloco G</div>
                <BotaoRelatorio onClick={handleRelatorioClick} className='dashboard-header-button' />
            </div>
            
            <div className='dropdown-container'>
                <div className='dropdown1'>
                    <Dropdown
                        id="myDropdown-dashboardType"
                        label="Diário"
                        options={dropdownOptions}
                        onChange={handleDropdownChange}
                    />
                    {selectedOption && <p>Opção selecionada: {selectedOption}</p>}
                </div>
                <div className='dropdown2'>
                    <Dropdown
                        id="myDropdown-dashboardDate"
                        label="Selecione o mês"
                        options={dropdownOptionsDate}
                        onChange={handleDropdownDateChange}
                    />
                    {selectedOptionDate && <p>Opção selecionada: {selectedOptionDate}</p>}
                </div>
            </div>

            <div className='periodo-consolidado'>Período Consolidado de 09/11/2023 a 08/12/2023</div>
            
            <div className='cards'> 
                {/* <h4>section CARDS</h4> */}
                {cardsData.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        value={card.value}
                        icon={card.icon}
                        className={card.className}
                    />
                ))}
            </div>

            <div className='card-barra-progresso'>
                <ProgressCard
                    title="Consumo percentual relativo à faixa atual (3ª faixa de tarifação)"
                    subtitle="Faixa de R$ 8.14 / R$ 8.14 (água / esgoto)"
                    progress={57}
                />
            </div>
            
            <div className='chart-container'>
                <div className='chart-barra'>
                    <Chart
                        title="Consumo em 2023-12"
                        labels={labels}
                        dailyData={dailyData}
                    />
                </div>

                <div className='chart-pizza'>
                    <PizzaChart
                        title="Consumo por dia da semana (m³)"
                        data={data}
                        labels={labelsPizza}
                    />
                </div>
            </div>
            
            {/* <div className='dashboard-data'>
                <pre>{JSON.stringify(dashboardData, null, 2)}</pre>
            </div> */}

            <WaterConsumptionCard 
                percentage={waterLevelPercentage} 
                consumption={waterConsumptionLiters} 
            />
        </div>
    );
    // ---------------------------------------------------------------------------------
};

export default MainContent;
