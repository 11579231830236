// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NavBar.css */
.navbar {
    margin: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 30px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 20px; */
    height: 97px;
    box-shadow: 0 5px 30px 1px rgba(63, 63, 63, 0.2); /* Horizontal offset, vertical offset, blur radius, spread radius, color */
}

.navbar-logo {
    background-color: aliceblue;
    margin-left: 24px;
    /* Estilos para o logo */
}

.navbar-items {
    background-color: aliceblue;
    /* Estilos para outros itens de navegação */
}

.search-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
} 

.navbar-search {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #a6adb5;
    /* padding-right: 34px; */
    cursor: pointer;
    transition: transform 0.4s ease; 
}

.navbar-divider {
    height: 50px;
    width: 1px;
    background-color: #e1e1e1;
    margin-right: 24px;
    
}

.navbar-search:hover {
    transform: rotate(45deg); /* Rotação do ícone ao passar o mouse */
    transform-origin: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,SAAS;IACT,WAAW;IACX,uBAAuB;IACvB,0CAA0C;IAC1C,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,qBAAqB;IACrB,YAAY;IACZ,gDAAgD,EAAE,0EAA0E;AAChI;;AAEA;IACI,2BAA2B;IAC3B,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,2BAA2B;IAC3B,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,+BAA+B;AACnC;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,yBAAyB;IACzB,kBAAkB;;AAEtB;;AAEA;IACI,wBAAwB,EAAE,uCAAuC;IACjE,wBAAwB;AAC5B","sourcesContent":["/* NavBar.css */\n.navbar {\n    margin: 0;\n    width: 100%;\n    background-color: white;\n    box-shadow: 0 30px 10px rgba(0, 0, 0, 0.1);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    /* padding: 0 20px; */\n    height: 97px;\n    box-shadow: 0 5px 30px 1px rgba(63, 63, 63, 0.2); /* Horizontal offset, vertical offset, blur radius, spread radius, color */\n}\n\n.navbar-logo {\n    background-color: aliceblue;\n    margin-left: 24px;\n    /* Estilos para o logo */\n}\n\n.navbar-items {\n    background-color: aliceblue;\n    /* Estilos para outros itens de navegação */\n}\n\n.search-right {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    padding-right: 24px;\n} \n\n.navbar-search {\n    display: flex;\n    align-items: center;\n    font-size: 24px;\n    color: #a6adb5;\n    /* padding-right: 34px; */\n    cursor: pointer;\n    transition: transform 0.4s ease; \n}\n\n.navbar-divider {\n    height: 50px;\n    width: 1px;\n    background-color: #e1e1e1;\n    margin-right: 24px;\n    \n}\n\n.navbar-search:hover {\n    transform: rotate(45deg); /* Rotação do ícone ao passar o mouse */\n    transform-origin: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
