// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.botao-relatorio {
    height: 40px;
    width: 170px;
    margin-right: 24px;
    background-color: #4E73DF; /* Cor de fundo azul */
    color: white; /* Texto em branco */
    padding: 10px 10px; /* Espaçamento interno */
    border: none; /* Sem borda */
    border-radius: 4px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho do texto */
    cursor: pointer; /* Cursor em forma de mãozinha */
    display: flex; /* Adicionado para alinhar ícone e texto */
    align-items: center; /* Alinha itens verticalmente */
    justify-content: center; /* Alinha itens horizontalmente */
    transition: background-color 0.3s;
}

@media (max-width: 1120px) {
    .botao-relatorio {
        margin-right: 10px;
        margin-left: 20px;
    }
}


.botao-relatorio:hover {
background-color: #0056b3; /* Cor de fundo ao passar o mouse */
}

.botao-relatorio .fa-download {
font-size: 1.2em;
margin-right: 7px; /* Espaço entre ícone e texto */
color: #A7B9EF; /* Cor do ícone */
}
`, "",{"version":3,"sources":["webpack://./src/components/BotaoRelatorio/BotaoRelatorio.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,yBAAyB,EAAE,sBAAsB;IACjD,YAAY,EAAE,oBAAoB;IAClC,kBAAkB,EAAE,wBAAwB;IAC5C,YAAY,EAAE,cAAc;IAC5B,kBAAkB,EAAE,wBAAwB;IAC5C,eAAe,EAAE,qBAAqB;IACtC,eAAe,EAAE,gCAAgC;IACjD,aAAa,EAAE,0CAA0C;IACzD,mBAAmB,EAAE,+BAA+B;IACpD,uBAAuB,EAAE,iCAAiC;IAC1D,iCAAiC;AACrC;;AAEA;IACI;QACI,kBAAkB;QAClB,iBAAiB;IACrB;AACJ;;;AAGA;AACA,yBAAyB,EAAE,mCAAmC;AAC9D;;AAEA;AACA,gBAAgB;AAChB,iBAAiB,EAAE,+BAA+B;AAClD,cAAc,EAAE,iBAAiB;AACjC","sourcesContent":[".botao-relatorio {\n    height: 40px;\n    width: 170px;\n    margin-right: 24px;\n    background-color: #4E73DF; /* Cor de fundo azul */\n    color: white; /* Texto em branco */\n    padding: 10px 10px; /* Espaçamento interno */\n    border: none; /* Sem borda */\n    border-radius: 4px; /* Bordas arredondadas */\n    font-size: 16px; /* Tamanho do texto */\n    cursor: pointer; /* Cursor em forma de mãozinha */\n    display: flex; /* Adicionado para alinhar ícone e texto */\n    align-items: center; /* Alinha itens verticalmente */\n    justify-content: center; /* Alinha itens horizontalmente */\n    transition: background-color 0.3s;\n}\n\n@media (max-width: 1120px) {\n    .botao-relatorio {\n        margin-right: 10px;\n        margin-left: 20px;\n    }\n}\n\n\n.botao-relatorio:hover {\nbackground-color: #0056b3; /* Cor de fundo ao passar o mouse */\n}\n\n.botao-relatorio .fa-download {\nfont-size: 1.2em;\nmargin-right: 7px; /* Espaço entre ícone e texto */\ncolor: #A7B9EF; /* Cor do ícone */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
