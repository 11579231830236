// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-to-top-button {
    position: fixed; /* Posição fixa na tela */
    bottom: 20px; /* Posicionado na parte inferior da tela */
    right: 20px; /* Posicionado à direita */
    background-color: #c8c8c8; /* Cor de fundo como na imagem */
    border: none; /* Sem borda */
    border-radius: 50%; /* Borda arredondada para fazer um círculo */
    display: flex; /* Utiliza flexbox para centralizar o ícone */
    justify-content: center; /* Centraliza o ícone horizontalmente */
    align-items: center; /* Centraliza o ícone verticalmente */
    cursor: pointer; /* Cursor como ponteiro */
    width: 40px; /* Largura fixa */
    height: 40px; /* Altura fixa */
    z-index: 1000; /* Certifique-se de que está acima de outros elementos */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);  */
}

.scroll-to-top-button:hover {
    background-color: #acacac;
}

.icon-top-scroll {
    color: white;
    font-size: medium;
}`, "",{"version":3,"sources":["webpack://./src/components/ScrollToTopButton/ScrollToTopButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,yBAAyB;IAC1C,YAAY,EAAE,0CAA0C;IACxD,WAAW,EAAE,0BAA0B;IACvC,yBAAyB,EAAE,gCAAgC;IAC3D,YAAY,EAAE,cAAc;IAC5B,kBAAkB,EAAE,4CAA4C;IAChE,aAAa,EAAE,6CAA6C;IAC5D,uBAAuB,EAAE,uCAAuC;IAChE,mBAAmB,EAAE,qCAAqC;IAC1D,eAAe,EAAE,yBAAyB;IAC1C,WAAW,EAAE,iBAAiB;IAC9B,YAAY,EAAE,gBAAgB;IAC9B,aAAa,EAAE,wDAAwD;IACvE,+CAA+C;AACnD;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".scroll-to-top-button {\n    position: fixed; /* Posição fixa na tela */\n    bottom: 20px; /* Posicionado na parte inferior da tela */\n    right: 20px; /* Posicionado à direita */\n    background-color: #c8c8c8; /* Cor de fundo como na imagem */\n    border: none; /* Sem borda */\n    border-radius: 50%; /* Borda arredondada para fazer um círculo */\n    display: flex; /* Utiliza flexbox para centralizar o ícone */\n    justify-content: center; /* Centraliza o ícone horizontalmente */\n    align-items: center; /* Centraliza o ícone verticalmente */\n    cursor: pointer; /* Cursor como ponteiro */\n    width: 40px; /* Largura fixa */\n    height: 40px; /* Altura fixa */\n    z-index: 1000; /* Certifique-se de que está acima de outros elementos */\n    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);  */\n}\n\n.scroll-to-top-button:hover {\n    background-color: #acacac;\n}\n\n.icon-top-scroll {\n    color: white;\n    font-size: medium;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
