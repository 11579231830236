// src/api/dashboardData.js

let intervalId = null;

const getDashboardData = async () => {
    try {
        const response = await fetch('https://www.akvofluo.com/api/dashboard/?ponto-de-medicao=Geral%20Hidroreader');
        if (!response.ok) {
            throw new Error(`Erro HTTP! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Dados recebidos:", data);
        return data;
    } catch (error) {
        console.error("Erro ao buscar dados da dashboard:", error);
        return null;
    }
};

export const startDataUpdate = (callback, interval = 60000) => {
    const fetchData = async () => {
        const data = await getDashboardData();
        if (data) {
            callback(data);
        }
    };

    // Busca dados imediatamente e então configura o intervalo
    fetchData();
    clearInterval(intervalId); // Limpa o intervalo anterior, se existir
    intervalId = setInterval(fetchData, interval);

    return () => clearInterval(intervalId); // Retorna uma função para limpar o intervalo
};

export default getDashboardData;
