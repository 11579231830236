// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    margin: 0;
    width: 100%; /* Estende o footer por toda a largura da tela */
    background-color: #ffffff; /* Fundo branco */
    /* box-shadow: 0 -0.8px 10px rgba(0, 0, 0, 0.2); */
    display: flex; /* Usa flexbox para alinhar conteúdo e botão */
    justify-content: center; /* Distribui o espaço entre conteúdo e botão */
    align-items: center; /* Centraliza verticalmente */
    /* padding: 0.5rem 1rem; */
    height: 75px;
}

.footer-content {
    font-size: 0.8rem; /* Tamanho do texto */
    color: #ACADB4;
}

.top-button {
    background: none; /* Sem fundo para parecer um botão plano */
    border: none; /* Sem borda */
    cursor: pointer; /* Muda o cursor para indicar que é clicável */
    font-size: 1.5rem; /* Tamanho do ícone do botão */
    padding: 0.5rem; /* Espaçamento interno */
    transition: background-color 0.3s ease; /* Transição suave de fundo */
}

.top-button:hover {
    background-color: #f0f0f0; /* Cor de fundo leve ao passar o mouse */
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,WAAW,EAAE,gDAAgD;IAC7D,yBAAyB,EAAE,iBAAiB;IAC5C,kDAAkD;IAClD,aAAa,EAAE,8CAA8C;IAC7D,uBAAuB,EAAE,8CAA8C;IACvE,mBAAmB,EAAE,6BAA6B;IAClD,0BAA0B;IAC1B,YAAY;AAChB;;AAEA;IACI,iBAAiB,EAAE,qBAAqB;IACxC,cAAc;AAClB;;AAEA;IACI,gBAAgB,EAAE,0CAA0C;IAC5D,YAAY,EAAE,cAAc;IAC5B,eAAe,EAAE,8CAA8C;IAC/D,iBAAiB,EAAE,8BAA8B;IACjD,eAAe,EAAE,wBAAwB;IACzC,sCAAsC,EAAE,6BAA6B;AACzE;;AAEA;IACI,yBAAyB,EAAE,wCAAwC;AACvE","sourcesContent":[".footer {\n    margin: 0;\n    width: 100%; /* Estende o footer por toda a largura da tela */\n    background-color: #ffffff; /* Fundo branco */\n    /* box-shadow: 0 -0.8px 10px rgba(0, 0, 0, 0.2); */\n    display: flex; /* Usa flexbox para alinhar conteúdo e botão */\n    justify-content: center; /* Distribui o espaço entre conteúdo e botão */\n    align-items: center; /* Centraliza verticalmente */\n    /* padding: 0.5rem 1rem; */\n    height: 75px;\n}\n\n.footer-content {\n    font-size: 0.8rem; /* Tamanho do texto */\n    color: #ACADB4;\n}\n\n.top-button {\n    background: none; /* Sem fundo para parecer um botão plano */\n    border: none; /* Sem borda */\n    cursor: pointer; /* Muda o cursor para indicar que é clicável */\n    font-size: 1.5rem; /* Tamanho do ícone do botão */\n    padding: 0.5rem; /* Espaçamento interno */\n    transition: background-color 0.3s ease; /* Transição suave de fundo */\n}\n\n.top-button:hover {\n    background-color: #f0f0f0; /* Cor de fundo leve ao passar o mouse */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
