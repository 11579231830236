// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.water-consumption-card {
    background-color: #ffffff;
    border-radius: 10px;
    /* padding: 20px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* text-align: center; */
    /* position: relative; */
    max-width: 400px; 
    border: 1px solid #EAECF4;
}

.water-consumption-card-title {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    text-align: center;
    background-color: #F8F9FC;
    padding: 16px;
    color: #4E73DF;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #EAECF4; /* Linha separadora */
}

.water-consumption-card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-around;
}

.wave-progress-container {
    position: relative;
    width: 160px; 
    height: 160px; 
    padding-bottom: 10px;
}

.consumption {
    font-size: 22px;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #858796;
}

.percentage-display {
    font-size: 22px;
    color: #333;
    
}
`, "",{"version":3,"sources":["webpack://./src/components/WaterConsumptionCard/WaterConsumptionCard.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,wCAAwC;IACxC,wBAAwB;IACxB,wBAAwB;IACxB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,4BAA4B;IAC5B,2BAA2B;IAC3B,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,gCAAgC,EAAE,qBAAqB;AAC3D;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kJAAkJ;IAClJ,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,WAAW;;AAEf","sourcesContent":[".water-consumption-card {\n    background-color: #ffffff;\n    border-radius: 10px;\n    /* padding: 20px; */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    /* text-align: center; */\n    /* position: relative; */\n    max-width: 400px; \n    border: 1px solid #EAECF4;\n}\n\n.water-consumption-card-title {\n    border-top-right-radius: 8px;\n    border-top-left-radius: 8px;\n    text-align: center;\n    background-color: #F8F9FC;\n    padding: 16px;\n    color: #4E73DF;\n    font-size: 16px;\n    font-weight: bold;\n    border-bottom: 1px solid #EAECF4; /* Linha separadora */\n}\n\n.water-consumption-card-content {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content:space-around;\n}\n\n.wave-progress-container {\n    position: relative;\n    width: 160px; \n    height: 160px; \n    padding-bottom: 10px;\n}\n\n.consumption {\n    font-size: 22px;\n    font-weight: 600;\n    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n    color: #858796;\n}\n\n.percentage-display {\n    font-size: 22px;\n    color: #333;\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
