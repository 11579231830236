// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pizza-chart-card {
    margin: auto;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #EAECF4;
    min-height: 265px;
    /* min-width: 280px; */
}

.pizza-card-header {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #F8F9FC;
    font-family: 'Nunito', sans-serif;
    text-align: center;
    color: #4E73DF;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #EAECF4; /* Linha separadora */
    margin-bottom: 15px;
    padding: 16px;
}

.chart-container-pizza {
    display: flex; /* Usa Flexbox para alinhar o filho (Doughnut) */
    flex-direction: column;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    max-height: 270px;
    padding-bottom: 20px;
    height: 100%; /* Ajuste para usar toda a altura disponível */
}
`, "",{"version":3,"sources":["webpack://./src/components/PizzaChart/PizzaChart.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;IACnB,uCAAuC;IACvC,yBAAyB;IACzB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,4BAA4B;IAC5B,2BAA2B;IAC3B,yBAAyB;IACzB,iCAAiC;IACjC,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,gCAAgC,EAAE,qBAAqB;IACvD,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa,EAAE,gDAAgD;IAC/D,sBAAsB;IACtB,uBAAuB,EAAE,+BAA+B;IACxD,mBAAmB,EAAE,6BAA6B;IAClD,iBAAiB;IACjB,oBAAoB;IACpB,YAAY,EAAE,8CAA8C;AAChE","sourcesContent":[".pizza-chart-card {\n    margin: auto;\n    box-sizing: border-box;\n    background-color: #fff;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    border: 1px solid #EAECF4;\n    min-height: 265px;\n    /* min-width: 280px; */\n}\n\n.pizza-card-header {\n    border-top-right-radius: 8px;\n    border-top-left-radius: 8px;\n    background-color: #F8F9FC;\n    font-family: 'Nunito', sans-serif;\n    text-align: center;\n    color: #4E73DF;\n    font-size: 16px;\n    font-weight: bold;\n    border-bottom: 1px solid #EAECF4; /* Linha separadora */\n    margin-bottom: 15px;\n    padding: 16px;\n}\n\n.chart-container-pizza {\n    display: flex; /* Usa Flexbox para alinhar o filho (Doughnut) */\n    flex-direction: column;\n    justify-content: center; /* Centraliza horizontalmente */\n    align-items: center; /* Centraliza verticalmente */\n    max-height: 270px;\n    padding-bottom: 20px;\n    height: 100%; /* Ajuste para usar toda a altura disponível */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
