// src/HomePage.js
import React from 'react';

const HomePage = () => {
    return (
        <div>
            <h1>Bem-vindo à Página Inicial!</h1>
        </div>
    );
}

export default HomePage;
