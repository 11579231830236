// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-card {
    margin: auto;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    border: 1px solid #EAECF4;
    min-height: 280px;
    min-width: 380px;
}

.chart-title {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-color: #F8F9FC;
    padding: 16px;
    color: #4E73DF;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #EAECF4; /* Linha separadora */
    text-align: center;
}

.chart-content {
    padding: 20px;
    color: #858796;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    min-height: 200px;
    min-width: 360px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chart/Chart.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,sCAAsC;IACtC,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;IAC5B,2BAA2B;IAC3B,yBAAyB;IACzB,aAAa;IACb,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,gCAAgC,EAAE,qBAAqB;IACvD,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".chart-card {\n    margin: auto;\n    box-sizing: border-box;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 5px 15px rgba(0,0,0,0.1);\n    border: 1px solid #EAECF4;\n    min-height: 280px;\n    min-width: 380px;\n}\n\n.chart-title {\n    border-top-right-radius: 8px;\n    border-top-left-radius: 8px;\n    background-color: #F8F9FC;\n    padding: 16px;\n    color: #4E73DF;\n    font-size: 16px;\n    font-weight: bold;\n    border-bottom: 1px solid #EAECF4; /* Linha separadora */\n    text-align: center;\n}\n\n.chart-content {\n    padding: 20px;\n    color: #858796;\n    font-weight: 600;\n    font-size: 13px;\n    text-align: center;\n    min-height: 200px;\n    min-width: 360px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
