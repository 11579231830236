// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  width: 100%; 
  
}

.app {
  background-color: #F8F9FC; 
  width: 100%;
  /* display: flex;
  flex-direction: row; */
  /* width: 100%; */
} 

.screen-all {
  background-color: #F8F9FC; 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 100vh;
  /* flex-grow: 1; */
  /* min-width: 100vh; */
  /* width: 100%; */
} 

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(#09444d, #6ec1dd); /* Degrade vertical */
  width: 230px; 
  height: auto; 
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 800px) {
  .sidebar {
      width: 170px;     
  }
}

.container {
  /* background-color: red;  */
  display: flex;
  flex-direction: column;
  /* align-items: flex-start;
  justify-content: flex-start; */
  min-height: 100vh; /* Se você quiser que o container tenha pelo menos a altura da tela */
  flex-grow: 1;
  max-width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;;AAEb;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX;wBACsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,6CAA6C,EAAE,qBAAqB;EACpE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;MACI,YAAY;EAChB;AACF;;AAEA;EACE,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB;gCAC8B;EAC9B,iBAAiB,EAAE,qEAAqE;EACxF,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".App {\n  text-align: center;\n  width: 100%; \n  \n}\n\n.app {\n  background-color: #F8F9FC; \n  width: 100%;\n  /* display: flex;\n  flex-direction: row; */\n  /* width: 100%; */\n} \n\n.screen-all {\n  background-color: #F8F9FC; \n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  min-height: 100vh;\n  /* flex-grow: 1; */\n  /* min-width: 100vh; */\n  /* width: 100%; */\n} \n\n.sidebar {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background: linear-gradient(#09444d, #6ec1dd); /* Degrade vertical */\n  width: 230px; \n  height: auto; \n  padding: 20px;\n  box-sizing: border-box;\n}\n\n@media (max-width: 800px) {\n  .sidebar {\n      width: 170px;     \n  }\n}\n\n.container {\n  /* background-color: red;  */\n  display: flex;\n  flex-direction: column;\n  /* align-items: flex-start;\n  justify-content: flex-start; */\n  min-height: 100vh; /* Se você quiser que o container tenha pelo menos a altura da tela */\n  flex-grow: 1;\n  max-width: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
