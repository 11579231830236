import React from 'react';
import './Footer.css'; // Certifique-se de criar este arquivo CSS

const Footer = () => {


return (
    <footer className="footer">
        <div className="footer-content">
        Copyright © Akvofluo 2023
        </div>
    </footer>
);
};

export default Footer;
